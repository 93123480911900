var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main shoko"},[_c('section',{staticClass:"main-section"},[(
        _vm.isPermissionAny([
          'Chairman',
          'BODArchiveAdmin',
          'NotificationManager' ])
      )?_c('p',{staticClass:"btn-item"},[_c('router-link',{attrs:{"to":{
          name: 'admin-bod-archive-edit',
          params: { id: 'create', mc_code: _vm.currentUser.mansion._id },
          query: {
            category_id: _vm.$route.params.category,
          },
        }}},[_c('button',{staticClass:"el-button el-button--primary",attrs:{"type":"button"}},[_c('i',{staticClass:"el-icon-circle-plus"}),_c('span',[_vm._v("新規書類追加")])])])],1):_vm._e(),_c('h3',{staticClass:"section-title green-left-head"},[_vm._v(" "+_vm._s(_vm.category.title)+" "),_c('span',{staticClass:"btn_to_list"},[_c('router-link',{attrs:{"to":{
            name: 'top',
            params: { mc_code: _vm.currentUser.mansion._id },
          }}},[_vm._v("戻る")])],1)]),_c('div',{staticClass:"pageCaption"},[_vm._v(" 管理組合様が自由に掲載できるページです。理事長様に掲載の権限がございます。 ")]),_vm._l((_vm.archives),function(item,index){return _c('div',{key:index},[_c('h4',{staticClass:"shoko-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"box-item"},[_c('div',{staticClass:"box-item-wrapper"},[(item.is_show_all)?_c('ul',{staticClass:"box-item-list"},_vm._l((item.archives),function(item2,index2){return _c('li',{key:index2,staticClass:"box-item-list-item"},[_c('div',{class:{
                  'type-pdf': _vm.isFileType(item2.files, ['pdf']),
                  pdfopenBtn: _vm.isFileType(item2.files, ['pdf']),
                  'type-ppt': _vm.isFileType(item2.files, ['ppt', 'pptx']),
                  'type-excel': _vm.isFileType(item2.files, ['xlsx']),
                  'type-image': _vm.isFileType(item2.files, [
                    'png',
                    'jpg',
                    'jpeg' ]),
                  'type-word': _vm.isFileType(item2.files, ['doc', 'docx']),
                }},[_c('a',{attrs:{"href":"javascript:void(0);"},on:{"click":function($event){_vm.onOpen(_vm.file(item2.files), item2._id);
                    return false;}}},[_vm._v(_vm._s(item2.title)),_c('span',{staticClass:"regdate"},[_vm._v("（"+_vm._s(_vm._f("moment")(item2.resgist_at,"YYYY.MM.DD"))+"）")])])]),_c('span',{staticClass:"btn-kanri"},[(
                    _vm.isPermissionAny([
                      'Chairman',
                      'BODArchiveAdmin',
                      'NotificationManager' ])
                  )?_c('router-link',{attrs:{"to":{
                    name: 'admin-bod-archive-edit',
                    params: {
                      id: item2._id,
                      mc_code: _vm.currentUser.mansion._id,
                    },
                  }}},[_vm._v(" 編集 ")]):_vm._e()],1)])}),0):_c('ul',{staticClass:"box-item-list"},_vm._l((item.archives.slice(
                0,
                _vm.default_show_items
              )),function(item2,index2){return _c('li',{key:index2,staticClass:"box-item-list-item"},[_c('div',{class:{
                  'type-pdf': _vm.isFileType(item2.files, ['pdf']),
                  pdfopenBtn: _vm.isFileType(item2.files, ['pdf']),
                  'type-ppt': _vm.isFileType(item2.files, ['ppt', 'pptx']),
                  'type-excel': _vm.isFileType(item2.files, ['xlsx', 'xls']),
                  'type-image': _vm.isFileType(item2.files, [
                    'png',
                    'jpg',
                    'jpeg' ]),
                  'type-word': _vm.isFileType(item2.files, ['doc', 'docx']),
                }},[_c('a',{attrs:{"href":"javascript:void(0);"},on:{"click":function($event){_vm.onOpen(_vm.file(item2.files), item2._id);
                    return false;}}},[_vm._v(_vm._s(item2.title)),_c('span',{staticClass:"regdate"},[_vm._v("（"+_vm._s(_vm._f("moment")(item2.resgist_at,"YYYY.MM.DD"))+"）")])])]),_c('span',{staticClass:"btn-kanri"},[(
                    _vm.isPermissionAny([
                      'Chairman',
                      'BODArchiveAdmin',
                      'NotificationManager' ])
                  )?_c('router-link',{attrs:{"to":{
                    name: 'admin-bod-archive-edit',
                    params: {
                      id: item2._id,
                      mc_code: _vm.currentUser.mansion._id,
                    },
                  }}},[_vm._v(" 編集 ")]):_vm._e()],1)])}),0)])]),(item.archives.length > _vm.default_show_items)?_c('div',{staticClass:"btn-more-wrapper",on:{"click":function($event){item.is_show_all = !item.is_show_all}}},[(item.is_show_all)?_c('span',{staticClass:"btn-more"},[_vm._v("閉じる")]):_c('span',{staticClass:"btn-more"},[_vm._v("もっとみる")])]):_vm._e()])}),_vm._m(0)],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPDFView),expression:"isPDFView"}],staticStyle:{"z-index":"100"},attrs:{"id":"pdfContainer"}},[_c('iframe',{staticStyle:{"overflow":"auto"},attrs:{"src":"","type":"application/pdf","width":"100%","height":"100%","id":"pdfView"}}),_c('div',{staticClass:"chatclose chatopen",on:{"click":function($event){_vm.isPDFView = false}}},[_vm._v("閉じる")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top-back-btn"},[_c('a',{attrs:{"href":"javascript:history.back();"}},[_vm._v("戻る")])])}]

export { render, staticRenderFns }