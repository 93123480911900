<template>
  <div class="main shoko kumiai">
    <section class="main-section">
      <p
        class="btn-item"
        v-if="
          isPermissionAny([
            'Chairman',
            'BODArchiveAdmin',
            'NotificationManager',
          ])
        "
      >
        <router-link
          :to="{
            name: 'admin-bod-archive-edit',
            params: { mc_code: currentUser.mansion._id, id: 'create' },
          }"
        >
          <button type="button" class="el-button el-button--primary">
            <i class="el-icon-circle-plus"></i><span>新規書類追加</span>
          </button>
        </router-link>
      </p>

      <h3 class="section-title green-left-head">管理組合書庫</h3>
      <div class="pageCaption">
        管理組合様が自由に掲載できるページです。理事長様に掲載の権限がございます。
      </div>
    </section>
    <!--/.main-section-->

    <section
      class="main-section buttons cloumBtns"
      v-if="
        isPermissionAny(['Chairman', 'BODArchiveAdmin', 'NotificationManager'])
      "
    >
      <p
        class="section-block center"
        v-for="(item, index) in categories"
        :key="index"
        :class="`archive${item._id}`"
      >
        <router-link
          :to="{
            name: 'bod-archive-list',
            params: { category: item._id, mc_code: currentUser.mansion._id },
          }"
        >
          <button class="bt bt-green">{{ item.title }}</button>
        </router-link>
      </p>
    </section>
    <section class="main-section buttons cloumBtns" v-else>
      <p
        class="section-block center"
        v-for="(item, index) in norm_categories"
        :key="index"
        :class="`archive${item._id}`"
      >
        <router-link
          :to="{
            name: 'bod-archive-list',
            params: { category: item._id, mc_code: currentUser.mansion._id },
          }"
        >
          <button class="bt bt-green">
            {{ item.title }}
          </button>
        </router-link>
      </p>
    </section>
    <!--/.main-section-->

    <!-- <IconMenu /> -->
    <!--/.main-section-->
    <div class="top-back-btn">
      <a href="https://iuc-salon.mansion-concierge.com/">トップページへ</a>
    </div>
  </div>
  <!--/.main-->
</template>

<script>
// import store from "../../../store";
import CategoryService from "../../../services/category.service";
// import IconMenu from "@/components/IconMenu.vue";
import ManagerArchiveService from "../../../services/bod-archive.service";
export default {
  name: "archive-index",
  // components: { IconMenu },
  // beforeRouteEnter(to, from, next) {
  //   const currentUser = store.state.auth.user;
  //   console.log(currentUser);
  //   if (currentUser && !currentUser.permissions.includes("Director")) {
  //     console.log(":401");
  //     next({ name: "notfound404" });
  //   } else {
  //     next();
  //   }
  // },
  data: function() {
    return {
      categories: [],
      counter: [],
    };
  },
  computed: {
    norm_categories() {
      return this.categories.filter((elem, index) => {
        return this.counter[index] != 0;
      });
    },
  },
  mounted: async function() {
    try {
      console.log("+++ mountd");
      const count = await ManagerArchiveService.count();
      this.counter = count.data;
      console.log(this.counter);
      const res = await CategoryService.getBoD();
      console.log(res);
      this.categories = res.data;
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style></style>
