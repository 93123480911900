<template>
  <div class="_login-wrapper" v-if="status == 'normal'">
    <h1 class="_login-main-logo">
      <img src="/i-salon/assets/img/common/logo-main.svg" alt="iuc SALON" />
    </h1>
    <!-- / _login-main-logo -->

    <div class="_login-form">
      <form
        name=""
        ref="ruleForm"
        @submit.prevent="onLogin"
        v-if="isDevelopment"
      >
        <div class="_login-list">
          <div class="_login-item -userid">
            <div class="_login-item-ic">
              <img src="/i-salon/assets/img/login/ic-userid.svg" alt="" />
            </div>
            <!-- / _login-item-ic -->
            <div class="_login-item-input">
              <h2 class="_login-item-ttl">ID</h2>
              <input
                class="_login-input"
                type="text"
                name="id"
                v-model.trim="$v.values.id.$model"
              />
              <div class="error" v-if="!$v.values.id.required">必須です</div>
            </div>
            <!-- / _login-item-input -->
          </div>
          <!-- / _login-input-item -->

          <div class="_login-item -password">
            <div class="_login-item-ic">
              <img src="/i-salon/assets/img/login/ic-password.svg" alt="" />
            </div>
            <!-- / _login-item-ic -->
            <div class="_login-item-input">
              <h2 class="_login-item-ttl">Password</h2>
              <input
                class="_login-input"
                :type="inputType"
                name="pw"
                v-model.trim="$v.values.password.$model"
              />
              <div class="error" v-if="!$v.values.password.required">
                必須です
              </div>
            </div>
            <!-- / _login-item-input -->
          </div>
          <!-- / _login-input-item -->
        </div>
        <!-- / _login-input-list -->
        <div class="el-form-item__content">
          <label class="el-checkbox">
            <span class="">
              <span class=""></span>
              <input type="checkbox" v-model="showPassword" />
            </span>
            <span class="el-checkbox__label">
              <span>パスワードを表示</span>
              <!---->
            </span>
          </label>
          <!---->
        </div>
        <div class="el-form-item__content">
          <label class="el-checkbox">
            <span class="">
              <span class=""></span>
              <input
                type="checkbox"
                v-model="isChecked"
                @change="changeCheckbox"
              />
            </span>
            <span class="el-checkbox__label">
              <span>入力情報を保存</span>
              <!---->
            </span>
          </label>
          <!---->
        </div>

        <input class="_login-submit" type="submit" value="ログイン" />
        <!-- / _login-submit -->
      </form>

      <form
        method="POST"
        name=""
        ref="ruleForm"
        :action="action"
        @submit.prevent="checkDaibunrui"
        v-else
      >
        <div class="_login-list">
          <div class="_login-item -userid">
            <div class="_login-item-ic">
              <img src="/i-salon/assets/img/login/ic-userid.svg" alt="" />
            </div>
            <!-- / _login-item-ic -->
            <div class="_login-item-input">
              <h2 class="_login-item-ttl">ID</h2>
              <input
                class="_login-input"
                type="text"
                name="id"
                v-model.trim="$v.values.id.$model"
              />
              <div class="error" v-if="!$v.values.id.required">必須です</div>
            </div>
            <!-- / _login-item-input -->
          </div>
          <!-- / _login-input-item -->

          <div class="_login-item -password">
            <div class="_login-item-ic">
              <img src="/i-salon/assets/img/login/ic-password.svg" alt="" />
            </div>
            <!-- / _login-item-ic -->
            <div class="_login-item-input">
              <h2 class="_login-item-ttl">Password</h2>
              <input
                class="_login-input"
                :type="inputType"
                name="pw"
                v-model.trim="$v.values.password.$model"
              />
              <div class="error" v-if="!$v.values.password.required">
                必須です
              </div>
            </div>
            <!-- / _login-item-input -->
          </div>
          <!-- / _login-input-item -->
        </div>
        <!-- / _login-input-list -->
        <div class="el-form-item__content">
          <label class="el-checkbox">
            <span class="">
              <span class=""></span>
              <input
                type="checkbox"
                aria-hidden="false"
                class=""
                v-model="showPassword"
              />
            </span>
            <span class="el-checkbox__label">
              <span>パスワードを表示</span>
              <!---->
            </span>
          </label>
          <!---->
        </div>
        <div class="el-form-item__content">
          <label class="el-checkbox">
            <span class="">
              <span class=""></span>
              <input
                type="checkbox"
                aria-hidden="false"
                class=""
                v-model="isChecked"
                @change="changeCheckbox"
              />
            </span>
            <span class="el-checkbox__label">
              <span>入力情報を保存</span>
              <!---->
            </span>
          </label>
          <!---->
        </div>
        <!-- <input type="hidden" name="status" v-model="kubun" /> -->
        <input class="_login-submit" type="submit" value="ログイン" />
        <!-- / _login-submit -->
      </form>
      <div class="login-err-msg">{{ message }}</div>
    </div>
    <!-- / _login-form -->

    <div class="_login-passreminder">
      <span class="_login-link-item">
        <a href="/contact">ID・パスワード共にお忘れの方</a><br /><br /><a
          href="/password-reminder"
          >パスワードのみお忘れの方</a
        >
      </span>
    </div>

    <div class="_login-link-list">
      <p class="_login-link-item">
        <a href="/i-salon/docs/kiyaku.html" target="_blank" ontouchstart=""
          >利用規約</a
        >
        <a href="/i-salon/docs/faq.html" target="_blank" ontouchstart=""
          >よくある質問</a
        >
      </p>
      <!-- / _login-link-item -->
    </div>
    <!-- / _login-link-list -->
  </div>
  <div class="_login-wrapper" v-else>
    <h1 class="_login-main-logo">
      <img src="/i-salon/assets/img/common/logo-main.svg" alt="iuc SALON" />
    </h1>
    <!-- / _login-main-logo -->

    <div class="_err">
      <div class="errBox">
        <h2 class="errTitle">エラー</h2>

        <p>
          <b
            >IDまたはパスワードに誤りがあります。<br /><br /><br /><a
              href="/contact"
              >ID・パスワード共にお忘れの方</a
            ><br /><a href="/password-reminder">パスワードのみお忘れの方</a></b
          >
        </p>

        <span class="err-back"
          ><a href="javascript:void(0);" @click="status = 'normal'"
            >戻る</a
          ></span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import User from "../models/user";
import store from "../store";

import firebase from "firebase";
import userService from "../services/user.service";
// import SSOService from "../services/sso.service";

const DEFAULT_ACTION = `${process.env.VUE_APP_ISALON_CHECK_ENDPOINT}`;
export default {
  name: "Login",
  data() {
    return {
      status: "normal",
      showPassword: false,
      isChecked: this.$localStorage.get("isSeved", false),
      successful: false,
      user: new User("", "", "", "", "", ""),
      loading: false,
      message: "",
      kubun: "",
      values: {
        id: "",
        password: "",
      },
      action: DEFAULT_ACTION,
      APP_QRCODE_LOGIN_SECRET_XOR_KEY:
        process.env.VUE_APP_QRCODE_LOGIN_SECRET_XOR_KEY,
    };
  },
  validations: {
    values: {
      id: {
        required,
      },
      password: {
        required,
      },
    },
  },
  watch: {
    isChecked(newValue) {
      console.log("+++ isChecked");
      console.log(newValue);
      this.$localStorage.set("isSeved", newValue);
    },
  },
  computed: {
    inputType: function() {
      return this.showPassword ? "text" : "password";
    },
    isDevelopment() {
      return (
        `${process.env.VUE_APP_MODE}` == "development" ||
        process.env.VUE_APP_NO_REDIRECT == 1
      );
    },
    // action() {
    //   return `${process.env.VUE_APP_ISALON_CHECK_ENDPOINT}`;
    // },
    loggedIn() {
      return store.state.auth.status.loggedIn;
    },
  },
  created() {
    console.log("++++ login.created");
    console.log("this.loggedIn", this.loggedIn);
    if (this.loggedIn) {
      this.$router.push({
        name: "top",
        params: { mc_code: this.currentUser.mansion._id },
      });
    }
  },
  async mounted() {
    console.log("++++ login.mounted");
    await this.$nextTick();
    if (this.$localStorage.get("isSeved")) {
      console.log(`isSeved`);
      this.values.id = this.$localStorage.get("id", "");
      this.values.password = this.$localStorage.get("password", "");
      console.log(this.values);
    }
    this.$emit("changeMansion", "");

    //QRコードからログイン
    this.toInputLoginInfoFromURI();
  },
  updated() {
    console.log("login.updated");
  },
  methods: {
    async checkDaibunrui() {
      console.log("+++checkDaibunrui");
      console.log(this.values);
      try {
        const result = await userService.preCheck(this.values.id);
        console.log(result);
        this.action = result.data.setting.login;
        // this.kubun = result.data.status;
        console.log(this.action);
        this.$nextTick(() => this.$refs.ruleForm.submit());
        return false;
      } catch (error) {
        this.status = "error";
        console.warn("error");
        console.log(error);
        return false;
      }
    },
    changeCheckbox() {
      console.log("changeCheckbox");
      console.log(this.isChecked);
    },
    async onLogin() {
      this.loading = true;
      const self = this;
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("error");
        this.loading = false;
        return;
      } else {
        if (this.values.id && this.values.password) {
          try {
            const result = await this.$store.dispatch(
              "auth/login",
              this.values,
            );
            console.log(result);
            if (self.$localStorage.get("isSeved")) {
              self.$localStorage.set("id", self.values.id);
              self.$localStorage.set("password", self.values.password);
            } else {
              self.$localStorage.set("id", "");
              self.$localStorage.set("password", "");
            }

            console.log("+++A");
            let messaging = null;
            if (firebase.messaging.isSupported()) {
              console.log("+++B");
              messaging = firebase.messaging();
              messaging
                .getToken({
                  vapidKey: `${process.env.VUE_APP_FIREBASE_VAPID_KEY}`,
                })
                .then(currentToken => {
                  console.log("+++C");
                  if (currentToken) {
                    console.log(`login currentToken=${currentToken}`);
                    userService.setToken(currentToken);
                    console.log("++OK");
                    // SSOService.signin("isalondemo", "demo01");

                    this.$router.push({
                      name: "top",
                      params: { mc_code: this.currentUser.mansion._id },
                    });
                  } else {
                    // Show permission request.
                    console.log(
                      "No registration token available. Request permission to generate one.",
                    );
                    // Show permission UI.
                    // updateUIForPushPermissionRequired();
                    // setTokenSentToServer(false);
                    // if (this.currentUser.email) {
                    this.$router.push({
                      name: "top",
                      params: { mc_code: this.currentUser.mansion._id },
                    });
                    // } else {
                    //   window.history.pushState(
                    //     null,
                    //     "トップ",
                    //     `/${this.currentUser.mansion._id}`,
                    //   );
                    //   this.$router.push({
                    //     name: "email-required",
                    //     params: { mc_code: this.currentUser.mansion._id },
                    //   });
                    // }
                  }
                })
                .catch(err => {
                  console.log(
                    "An error occurred while retrieving token. ",
                    err,
                  );

                  // if (this.currentUser.email) {
                  this.$router.push({
                    name: "top",
                    params: { mc_code: this.currentUser.mansion._id },
                  });
                  // } else {
                  //   window.history.pushState(
                  //     null,
                  //     "トップ",
                  //     `/${this.currentUser.mansion._id}`,
                  //   );
                  //   this.$router.push({
                  //     name: "email-required",
                  //     params: { mc_code: this.currentUser.mansion._id },
                  //   });
                  // }
                  // showToken("Error retrieving registration token. ", err);
                  // setTokenSentToServer(false);
                });
            } else {
              console.log("+++++ login ++");
              console.log(this.currentUser);
              // if (this.currentUser.email) {
              this.$router.push({
                name: "top",
                params: { mc_code: this.currentUser.mansion._id },
              });
            }
          } catch (error) {
            this.status = "error";
            console.log(error);
            console.log("singin erro!");
            this.successful = false;
            this.loading = false;
            console.log(error);
            this.message = "ログインできませんでした。";
          }
        }
      }
    },
    async handleLogin(formName) {
      this.loading = true;
      const self = this;
      this.$refs[formName].validate(async valid => {
        if (!valid) {
          this.loading = false;
          return;
        }
        console.log("singin");
        console.log(this.user);
        if (this.user.id && this.user.password) {
          try {
            const result = await this.$store.dispatch("auth/login", this.user);
            console.log(result);
            if (self.$localStorage.get("isSeved")) {
              self.$localStorage.set("id", self.user.id);
              self.$localStorage.set("password", self.user.password);
            } else {
              self.$localStorage.set("id", "");
              self.$localStorage.set("password", "");
            }

            console.log("+++A");
            let messaging = null;
            if (firebase.messaging.isSupported()) {
              console.log("+++B");
              messaging = firebase.messaging();
              messaging
                .getToken({
                  vapidKey: `${process.env.VUE_APP_FIREBASE_VAPID_KEY}`,
                })
                .then(currentToken => {
                  console.log("+++C");
                  if (currentToken) {
                    console.log(`login currentToken=${currentToken}`);
                    userService.setToken(currentToken);
                    console.log("++OK");
                    // SSOService.signin("isalondemo", "demo01");

                    this.$router.push({
                      name: "top",
                      params: { mc_code: this.currentUser.mansion._id },
                    });
                  } else {
                    // Show permission request.
                    console.log(
                      "No registration token available. Request permission to generate one.",
                    );
                    // Show permission UI.
                    // updateUIForPushPermissionRequired();
                    // setTokenSentToServer(false);
                    this.$router.push({
                      name: "top",
                      params: { mc_code: this.currentUser.mansion._id },
                    });
                  }
                })
                .catch(err => {
                  console.log(
                    "An error occurred while retrieving token. ",
                    err,
                  );

                  this.$router.push({
                    name: "top",
                    params: { mc_code: this.currentUser.mansion._id },
                  });
                  // showToken("Error retrieving registration token. ", err);
                  // setTokenSentToServer(false);
                });
            } else {
              this.$router.push({
                name: "top",
                params: { mc_code: this.currentUser.mansion._id },
              });
            }
          } catch (error) {
            console.log(error);
            console.log("singin erro!");
            this.successful = false;
            this.loading = false;
            console.log(error);
            this.message = "ログインできませんでした。";
          }
        }
      });
    },
    hexToString(hexStr) {
      // 16進数文字列を通常の文字列に変換
      let str = "";
      for (let i = 0; i < hexStr.length; i += 2) {
        str += String.fromCharCode(parseInt(hexStr.substr(i, 2), 16));
      }
      return str;
    },
    xorDecrypt(encryptedStr, key) {
      // XOR復号処理
      let decryptedText = "";
      let keyLength = key.length;
      for (let i = 0; i < encryptedStr.length; i++) {
        decryptedText += String.fromCharCode(
          encryptedStr.charCodeAt(i) ^ key.charCodeAt(i % keyLength),
        );
      }
      return decryptedText;
    },
    generateDecryptedText(encryptedText, XORkey) {
      let encryptedStr = this.hexToString(encryptedText);
      let decryptedText = this.xorDecrypt(encryptedStr, XORkey);
      return decryptedText;
    },
    generateKey(kt) {
      if (kt === "isalon") {
        return this.APP_QRCODE_LOGIN_SECRET_XOR_KEY;
      }
    },
    targetTimestampWithOneHourWithinIs(timeStamp) {
      //対象のタイムスタンプから一時間以内か
      console.log("timeStamp", timeStamp);
      const now = Math.floor(Date.now() / 1000); // 現在のUNIXタイムスタンプ（秒単位）
      const oneHourAgo = now - 3600; // 1時間前のタイムスタンプ
      return (
        Math.floor(timeStamp / 1000) >= oneHourAgo &&
        Math.floor(timeStamp / 1000) <= now
      );
    },
    checkReferrer() {
      const referrer = document.referrer; // 遷移元URLを取得
      const qrCodeOriginDomain = "https://i-salon.mansion-concierge.com"; //qrコードのドメイン

      if (!referrer) {
        // ブックマークやアドレスバー直接入力は禁止
        alert("エラー: 直接アクセスは禁止されています。");
        return false;
      }

      if (!referrer.startsWith(qrCodeOriginDomain)) {
        // 遷移元が異なるドメインならエラー
        alert("エラー: 遷移元が異なるドメインです。");
        return false;
      }

      return true;
    },
    toInputLoginInfoFromURI() {
      const query = this.$route.query;
      const queryKeys = ["kt", "ac", "pc", "tc"];
      const hasAnyQuery = queryKeys.some(key => key in query);
      const uriIssuedAfterOneHourWithinIs = this.targetTimestampWithOneHourWithinIs(
        query.tc,
      );

      //クエリは存在しているか
      if (!hasAnyQuery) return;
      //同じドメインから遷移しているか
      if (!this.checkReferrer() && hasAnyQuery) return;
      //URIを発行した後一時間以内か
      if (!uriIssuedAfterOneHourWithinIs) return;

      const xorKey = this.generateKey(query.kt);
      const id = this.generateDecryptedText(query.ac, xorKey);
      const pass = this.generateDecryptedText(query.pc, xorKey);

      this.values.id = id;
      this.values.password = pass;
      console.log(`generateid:${id}`);
      console.log(`generatepass:${pass}`);
    },
  },
};
</script>

<style></style>
