import AuthService from "../services/auth.service";
import { encryptStorage } from "../libs/encrypt-storage";

console.log(1);
let user = null;
try {
  user = encryptStorage.getItem("user");
} catch (error) {
  console.warn(error);
  user = null;
}

console.log(2);
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    async login({ commit }, user) {
      try {
        console.log("login+++");
        const result = await AuthService.login(user);
        console.log(result);
        console.log("AUTH loggedin");
        commit("loginSuccess", result);
        return result;
        // return Promise.resolve(user);
      } catch (error) {
        console.log("AUTH Erro");
        commit("loginFailure");
        throw error;
        // return Promise.reject(error);
      }
    },
    async sso({ commit }, query) {
      try {
        console.log(query);
        const result = await AuthService.sso(query);
        console.log("store sso");
        console.log(result);

        // console.log(result.response.status);
        if (result.status == "200") {
          console.log("SSOloginSuccess");
          console.log(result.data);
          commit("loginSuccess", result.data);
          return Promise.resolve(result.data);
        } else {
          console.log("SSOloginFailure");
          commit("loginFailure");
          return Promise.reject(result);
        }
      } catch (error) {
        console.log(error);
        commit("loginFailure");
        return Promise.reject(error);
      }
      // return AuthService.sso(query).then(
      //   (user) => {
      //     console.log("SSOloginSuccess");
      //     commit("loginSuccess", user);
      //     return Promise.resolve(user);
      //   },
      //   (error) => {
      //     commit("SSOloginFailure");
      //     return Promise.reject(error);
      //   }
      // );
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit("registerSuccess");
          return Promise.resolve(response.data);
        },
        error => {
          commit("registerFailure");
          return Promise.reject(error);
        },
      );
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
  },
  getters: {
    isPermission: state => value => {
      return state.user != null
        ? state.user.permissions.includes(value)
        : false;
    },
    isPermissionAny: state => value => {
      return state.user != null
        ? state.user.permissions.some(p => value.includes(p))
        : false;
    },
  },
};
